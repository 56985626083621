<template>
    <div>
        <p>{{ msg }}</p>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        msg: null
    },
    data() {
        return {

        }
    }
}
</script>