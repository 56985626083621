<template>
	<v-card>
		<v-card-title>
			<v-container>
				<v-row>
					<v-badge
						:content="fastTradeCount"
						:value="fastTradeCount"
						color="red"
						inline
					>
						Fast Trade
					</v-badge>
					<v-spacer></v-spacer>

					<v-dialog v-model="dialog" max-width="500px">
						<template v-slot:activator="{ on }">
							<v-btn
								class="mx-2"
								fab
								dark
								x-small
								color="orange"
								v-on="on"
							>
								<v-icon dark>mdi-cog-outline</v-icon>
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="headline">Fast Trade Setting</span>
							</v-card-title>

							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12" sm="6" md="6">
											<v-text-field
												hint="x 100"
												:persistent-hint="
													isPersistentHint
												"
												v-model="
													fastTradeSetting.lotThreshold
												"
												label="Lot Threshold >= "
											></v-text-field>
										</v-col>
										<v-col cols="12" sm="6" md="6">
											<v-text-field
												v-model="
													fastTradeSetting.timeThreshold
												"
												label="Second Threshold =< "
											></v-text-field>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" text @click="close"
									>Cancel</v-btn
								>
								<v-btn
									color="blue darken-1"
									text
									@click="saveSetting"
									>Save</v-btn
								>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-btn
						class="mx-2"
						fab
						dark
						x-small
						color="green"
						@click="toCSV"
					>
						<v-icon dark>mdi-microsoft-excel</v-icon>
					</v-btn>
					<v-btn
						class="gray accent-4 ml-2"
						x-small
						fab
						@click="subscribe"
						:isSubscribe="isSubscribe"
						:loading="isSubscribeLoading"
						:style="
							isSubscribe
								? 'background-color: red;'
								: 'background-color: gray;'
						"
					>
						<v-icon dark>
							{{
								isSubscribe ? "mdi-email-check" : "mdi-email"
							}}</v-icon
						>
					</v-btn>
				</v-row>
				<v-row>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Search"
						single-line
						hide-details
						class="mr-5 mb-4"
					></v-text-field>
				</v-row>
			</v-container>
		</v-card-title>
		<v-data-table
			:headers="headers"
			:items="deals"
			:items-per-page="itemsPerPage"
			:sort-by="tableSort"
			:sort-desc="tableSortOrder"
			fixed-header
			height="288"
			hide-default-footer
			:search="search"
		>
			<template v-slot:item.volume="props">
				<v-tooltip right color="transparent">
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							{{ props.item.volume }}
						</span>
					</template>
					<v-list dense>
						<v-subheader>Details</v-subheader>
						<v-list-item
							v-for="(item, i) in infos[props.item.order]"
							:key="i"
						>
							<v-list-item-content>
								<v-list-item-title
									v-text="item.title"
								></v-list-item-title>
								<v-list-item-subtitle
									twoLine
									v-html="item.value"
								></v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
// import AlarmDataService from "../../services/AlarmDataService";
// import SettingService from "../../services/SettingService";
// import PKControlService from "../../services/PKControlService";
import { mapState } from "vuex";
export default {
	beforeMount() {
		this.FastTradesStreaming();
		this.getSubsribeStatus();
		this.getFastTradeMonitorSetting();
	},
	computed: mapState({
		fastTradeCount() {
			return this.deals.length;
		},
		userEmail: state => state.userEmail,
		userName: state => state.userName,
		userId: state => state.userId,
		userRole: state => state.userRole,
		userInfo() {
			return {
				userName: this.userName,
				userId: this.userId,
				userRole: this.userRole
			};
		}
	}),
	watch: {
		dialog(val) {
			val || this.close();
		}
	},
	methods: {
		getSubsribeStatus() {
			// let vm = this;
			// SettingService.GetEmailReceiver(
			// 	{ type: "FastTradeAlarm-Receivers", email: this.userEmail },
			// 	(err, response) => {
			// 		if (response != null) {
			// 			if (response.toObject().statusCode == 0) {
			// 				vm.isSubscribe = true;
			// 			}
			// 		}
			// 	}
			// );
		},
		subscribe() {
			// let vm = this;
			// if (!this.isSubscribe) {
			// 	this.isSubscribeLoading = true;
			// 	let sendType = "bcc";

			// 	if (this.userRole == "admin") {
			// 		sendType = "to";
			// 	}
			// 	SettingService.SubscribeAlarm(
			// 		{
			// 			type: "FastTradeAlarm-Receivers",
			// 			email: this.userEmail,
			// 			sendType: sendType
			// 		},
			// 		(err, response) => {
			// 			if (err) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: err,
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else if (response.toObject().msg == 0) {
			// 				vm.isSubscribe = true;
			// 				vm.promptInfoSnack({
			// 					text: "Already Subscribed",
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else {
			// 				vm.isSubscribe = !vm.isSubscribe;
			// 			}
			// 			vm.isSubscribeLoading = false;
			// 		}
			// 	);
			// } else {
			// 	this.isSubscribeLoading = true;
			// 	SettingService.UnsubscribeAlarm(
			// 		{ type: "FastTradeAlarm-Receivers", email: this.userEmail },
			// 		(err, response) => {
			// 			if (err) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: err,
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else if (response.toObject().msg == 0) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: "Subscribe Type Not Exist",
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else {
			// 				vm.isSubscribe = !vm.isSubscribe;
			// 			}
			// 			vm.isSubscribeLoading = false;
			// 		}
			// 	);
			// }
		},
		toCSV() {
			this.$ToCSV("FastTrade", this.csvHeader, this.deals);
		},
		getFastTradeMonitorSetting() {
			// let vm = this;
			// SettingService.GetFastTradeMonitorSetting((err, response) => {
			// 	if (err) {
			// 		vm.promptInfoSnack({
			// 			text: "Fast Trade Empety Setting",
			// 			status: true,
			// 			color: "red"
			// 		});
			// 	} else {
			// 		vm.fastTradeSetting.lotThreshold = response.toObject().lotThreshold;
			// 		vm.fastTradeSetting.timeThreshold = response.toObject().timeThreshold;
			// 	}
			// });
		},
		close() {
			this.dialog = false;
		},
		async saveSetting() {
			// let vm = this;
			// this.isSettingLoading = true;
			// try {
			// 	await SettingService.SetFastTradeMonitorSetting({
			// 		...this.fastTradeSetting,
			// 		...this.userInfo
			// 	});
			// 	await PKControlService.UpdatePKSetting(
			// 		"FastTrade",
			// 		this.userInfo
			// 	);
			// 	this.isSettingLoading = false;
			// 	this.close();
			// 	vm.promptInfoSnack({
			// 		text: "Change Successfully",
			// 		status: true,
			// 		color: "success"
			// 	});
			// } catch (err) {
			// 	this.isSettingLoading = false;
			// 	this.close();
			// 	vm.promptInfoSnack({ text: err, status: true, color: "red" });
			// }
		},
		FastTradesStreaming() {
			// let vm = this;
			// this.stream = AlarmDataService.ConstructFastTradeStream({
			// 	...vm.userInfo
			// });
			// this.stream.on("status", function(status) {
			// 	console.log(status.code);
			// 	console.log(status.details);
			// 	console.log(status.metadata);
			// });
			// this.stream.on("end", function(end) {
			// 	// stream end signal
			// });
			// this.stream.on("data", function(response) {
			// 	if (response != null) {
			// 		let fastTrades = response.toObject();
			// 		let deals = fastTrades.alarmsList.map(x => {
			// 			if (x.direction == "1") x.direction = "Sell";
			// 			if (x.direction == "0") x.direction = "Buy";
			// 			x.volume = (x.volume * 0.01).toFixed(2);
			// 			x.priceClose = x.priceClose.toFixed(2);
			// 			x.timeCreate = new Date(x.timeCreate * 1000)
			// 				.toISOString()
			// 				.slice(0, -5)
			// 				.replace("T", " ");
			// 			x.timeClose = new Date(x.timeClose * 1000)
			// 				.toISOString()
			// 				.slice(0, -5)
			// 				.replace("T", " ");
			// 			vm.infos[x.order] = vm.concstructDetailObject(x);
			// 			return x;
			// 		});
			// 		deals.sort(function(a, b) {
			// 			return a.order - b.order;
			// 		});
			// 		vm.deals = deals;
			// 	}
			// });
		},
		promptInfoSnack(message) {
			this.$store.dispatch("alarmCenter/toggleInfoSnack", message);
		},
		concstructDetailObject(trade) {
			let result = [
				{ title: "Order", value: trade.order },
				{ title: "Direction", value: trade.direction },
				{ title: "Price Open", value: trade.priceOpen },
				{ title: "Time Open", value: trade.timeCreate },
				{ title: "Price Close", value: trade.priceClose },
				{ title: "Time Close", value: trade.timeClose },
				{ title: "PNL", value: trade.pnl }
			];
			return result;
		}
	},
	data() {
		return {
			infos: {},
			stream: {},
			dialog: false,
			isSubscribe: false,
			isSubscribeLoading: false,
			isSettingLoading: false,
			isPersistentHint: true,
			tableSort: ["login", "timeCreate"],
			tableSortOrder: [true, true],
			fastTradeSetting: {
				lotThreshold: 0,
				timeThreshold: 0
			},
			itemsPerPage: -1,
			search: "",
			csvHeader: {
				login: "Login",
				order: "Order",
				book: "Book",
				symbol: "Symbol",
				directio: "Direction",
				volume: "Volume",
				priceOpen: "priceOpen",
				timeCreate: "timeCreate",
				priceClose: "priceClose",
				timeClose: "timeClose",
				pnl: "Pnl"
			},
			headers: [
				{
					text: "Login",
					align: "start",
					value: "login"
				},
				// { text: 'Order', value: 'order' },
				{ text: "Book", value: "book" },
				{ text: "Symbol", value: "symbol" },
				// { text: "Direction", value: 'direction'},
				{ text: "Volume", value: "volume" }
				// { text: 'PriceOpen', value: 'priceOpen' },
				// { text: 'TimeCreate', value: 'timeCreate' },
				// { text: 'PriceClose', value: 'priceClose' },
				// { text: 'TimeClose', value: 'timeClose' },
				// { text: 'PNL', value: 'pnl' },
			],
			deals: []
		};
	}
};
</script>
