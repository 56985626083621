<template>
	<div>
		<v-card>
			<v-card-title>
				<v-container>
					<v-row>
						<v-badge
							:content="bigLotCount"
							:value="bigLotCount"
							color="red"
							inline
						>
							Big Lot
						</v-badge>
						<v-spacer></v-spacer>

						<v-dialog v-model="dialog" max-width="500px">
							<template v-slot:activator="{ on }">
								<v-btn
									class="mx-2"
									fab
									dark
									x-small
									color="orange"
									v-on="on"
								>
									<v-icon dark>mdi-cog-outline</v-icon>
								</v-btn>
							</template>
							<v-card :loading="isSettingLoading">
								<v-card-title>
									<span class="headline"
										>Big Lot Setting</span
									>
								</v-card-title>
								<v-card-text>
									<v-container>
										<v-row>
											<v-col cols="12" sm="6" md="6">
												<v-text-field
													hint="x 100"
													:persistent-hint="
														isPersistentHint
													"
													v-model="
														bigLotSetting.lotThreshold
													"
													label="Big Lot >= "
												></v-text-field>
											</v-col>
										</v-row>
									</v-container>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
										color="blue darken-1"
										text
										@click="close"
										>Cancel</v-btn
									>
									<v-btn
										color="blue darken-1"
										text
										@click="saveSetting"
										>Save</v-btn
									>
								</v-card-actions>
							</v-card>
						</v-dialog>
						<v-btn
							class="mx-2"
							fab
							dark
							x-small
							color="green"
							@click="toCSV"
						>
							<v-icon dark>mdi-microsoft-excel</v-icon>
						</v-btn>
						<v-btn
							class="gray accent-4 ml-2"
							x-small
							fab
							@click="subscribe"
							:isSubscribe="isSubscribe"
							:loading="isSubscribeLoading"
							:style="isSubscribe ? 'background-color: red;' : 'background-color: gray;'"
						>
							<v-icon dark>{{ isSubscribe ? "mdi-email-check" : "mdi-email" }}</v-icon>
						</v-btn>
					</v-row>
					<v-row>
						<v-text-field
							v-model="search" append-icon="mdi-magnify"
							label="Search" single-line hide-details class="mr-5 mb-4" width="50"></v-text-field>
					</v-row>
				</v-container>
			</v-card-title>
			<v-data-table :headers="headers" :items-per-page="itemsPerPage"
				hide-default-footer :items="data" :sort-by="tableSort"
				:sort-desc="tableSortOrder" fixed-header height="288" :search="search">
				<template v-slot:item.volume="props">
					<v-tooltip right color="transparent">
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on">
								{{ props.item.volume }}
							</span>
						</template>
						<v-list dense>
							<v-subheader>Details</v-subheader>
							<v-list-item v-for="(detail, i) in props.item" :key="i">
								<v-list-item-content>
									<v-list-item-title v-text="i"></v-list-item-title>
									<v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	props: {
		data: {
			default: [],
            type: Array, 
		}
	},
	watch: {
		dialog(val) {
			val || this.close();
		}
	},
	computed: mapState({
		bigLotCount() {
			return this.positions.length;
		},
		userEmail: state => state.userEmail,
		userName: state => state.userName,
		userId: state => state.userId,
		userRole: state => state.userRole,
		userInfo() {
			return {
				userName: this.userName,
				userId: this.userId,
				userRole: this.userRole
			};
		}
	}),
	beforeMount() {
		// this.BigLotStreaming();
		// this.getSubsribeStatus();
		// this.getBigLotMonitorSetting();
	},
	beforeDestroy() {
		// this.stream.cancel();
	},
	mounted() {
		console.log(this.data);
	},
	methods: {
		getSubsribeStatus() {
			// let vm = this;
			// SettingService.GetEmailReceiver(
			// 	{ type: "BigLotAlarm-Receivers", email: this.userEmail },
			// 	(err, response) => {
			// 		if (response != null) {
			// 			if (response.toObject().statusCode == 0) {
			// 				vm.isSubscribe = true;
			// 			}
			// 		}
			// 	}
			// );
		},
		subscribe() {
			// let vm = this;
			// if (!this.isSubscribe) {
			// 	this.isSubscribeLoading = true;
			// 	let sendType = "bcc";

			// 	if (this.userRole == "admin") {
			// 		sendType = "to";
			// 	}
			// 	SettingService.SubscribeAlarm(
			// 		{
			// 			type: "BigLotAlarm-Receivers",
			// 			email: this.userEmail,
			// 			sendType: sendType
			// 		},
			// 		(err, response) => {
			// 			if (err) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: err,
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else if (response.toObject().msg == 0) {
			// 				vm.isSubscribe = true;
			// 				vm.promptInfoSnack({
			// 					text: "Already Subscribed",
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else {
			// 				vm.isSubscribe = !vm.isSubscribe;
			// 			}
			// 			vm.isSubscribeLoading = false;
			// 		}
			// 	);
			// } else {
			// 	this.isSubscribeLoading = true;
			// 	SettingService.UnsubscribeAlarm(
			// 		{ type: "BigLotAlarm-Receivers", email: this.userEmail },
			// 		(err, response) => {
			// 			if (err) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: err,
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else if (response.toObject().msg == 0) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: "Subscriber Not Exist",
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else {
			// 				vm.isSubscribe = !vm.isSubscribe;
			// 			}
			// 			vm.isSubscribeLoading = false;
			// 		}
			// 	);
			// }
		},
		getBigLotMonitorSetting() {
			// let vm = this;
			// SettingService.GetBigLotMonitorSetting((err, response) => {
			// 	if (err) {
			// 		vm.promptInfoSnack({
			// 			text: "Big Lot Empety Setting",
			// 			status: true,
			// 			color: "red"
			// 		});
			// 	} else {
			// 		vm.bigLotSetting.lotThreshold = response.toObject().lotThreshold;
			// 	}
			// });
		},
		toCSV() {
			this.$ToCSV("BigLot", this.csvHeader, this.positions);
		},
		close() {
			this.dialog = false;
		},
		async saveSetting() {
			// let vm = this;
			// this.isSettingLoading = true;
			// try {
			// 	await SettingService.SetBigLotMonitorSetting({
			// 		...this.bigLotSetting,
			// 		...this.userInfo
			// 	});
			// 	await PKControlService.UpdatePKSetting("BigLot", this.userInfo);
			// 	this.isSettingLoading = false;
			// 	this.close();
			// 	vm.promptInfoSnack({
			// 		text: "Change Successfully",
			// 		status: true,
			// 		color: "success"
			// 	});
			// } catch (err) {
			// 	this.isSettingLoading = false;
			// 	this.close();
			// 	vm.promptInfoSnack({ text: err, status: true, color: "red" });
			// }
		},
		BigLotStreaming() {
			// let vm = this;
			// this.stream = AlarmDataService.ConstructBigLotStream({
			// 	...vm.userInfo
			// });
			// this.stream.on("status", function(status) {
			// 	console.log(status.code);
			// 	console.log(status.details);
			// 	console.log(status.metadata);
			// });
			// this.stream.on("end", function(end) {
			// 	// stream end signal
			// });
			// this.stream.on("data", function(response) {
			// 	if (response != null) {
			// 		let bigLots = response.toObject();
			// 		vm.positions = bigLots.alarmsList.map(x => {
			// 			if (x.action == "1") x.action = "Sell";
			// 			if (x.action == "0") x.action = "Buy";
			// 			x.volume = (x.volume * 0.01).toFixed(2);
			// 			x.priceClose = x.priceClose.toFixed(2);
			// 			x.timeCreate = new Date(x.timeCreate * 1000)
			// 				.toISOString()
			// 				.slice(0, -5)
			// 				.replace("T", " ");
			// 			x.timeClose = new Date(x.timeClose * 1000)
			// 				.toISOString()
			// 				.slice(0, -5)
			// 				.replace("T", " ");
			// 			vm.infos[x.order] = vm.concstructDetailObject(x);
			// 			return x;
			// 		});
			// 	}
			// });
		},
		promptInfoSnack(message) {
			this.$store.dispatch("alarmCenter/toggleInfoSnack", message);
		},
		concstructDetailObject(trade) {
			let result = [
				{ title: "Order", value: trade.order },
				{ title: "action", value: trade.action },
				{ title: "Price Open", value: trade.openPrice },
				{ title: "Time Open", value: trade.timeCreate },
				{ title: "Price Close", value: trade.priceClose },
				{ title: "Time Close", value: trade.timeClose },
				{ title: "PNL", value: trade.pnl }
			];
			return result;
		}
	},
	data() {
		return {
			infos: {},
			positions: [],
			stream: {},
			dialog: false,
			isSubscribe: false,
			isSubscribeLoading: false,
			isSettingLoading: false,
			isPersistentHint: true,
			tableSort: ["timeCreate"],
			tableSortOrder: [true],
			itemsPerPage: -1,
			search: "",
			bigLotSetting: {
				lotThreshold: 0
			},
			csvHeader: {
				login: "Login",
				order: "Order",
				book: "Book",
				symbol: "Symbol",
				directio: "Directio",
				volume: "Volume",
				openPrice: "OpenPrice",
				timeCreate: "timeCreate",
				priceClose: "priceClose",
				timeClose: "timeClose",
				pnl: "Pnl"
			},
			headers: [
				{ text: "Broker", value: "broker" },
				{ text: "Login", value: "login" },
				{ text: "Book", value: "book" },
				{ text: "Symbol", value: "symbol" },
				{ text: "Volume", value: "volume" },
				// { text: "Time", value: "time" },
				// { text: "Broker", value: "broker" },
				// { text: "Group", value: "group" },
				// { text: "Message", value: "message" },
				// { text: "Order", value: "order" },
				// { text: "Direction", value: "direction" },
			]
		};
	}
};
</script>
