import { mapState } from "vuex";

export const alert = {
	computed: {
		...mapState(["alertNotificationShowItem", "disalbeNotificationAlert"])
	},
	methods: {
		cacheLoad(data) {

		},
		cacheSave(data) {
			
			let key = "alertCache:" + data[0].broker + ":" + data[0].alert_name;

			if (localStorage.getItem(key) == null || localStorage.getItem(key) == undefined) {
				let localData = data;
				localStorage.setItem(key, JSON.stringify(localData));
			} else {
				let localData = JSON.parse(localStorage.getItem(key));
				localData.unshift(data[0]);
				localStorage.setItem(key, JSON.stringify(localData));
			}
		},
		/**
		 * Remove some unused channel
		 */
		filterChannel(data) {
			if (this.disalbeNotificationAlert.includes(data)) return true;

			return false;
		},
		alertPermissionDataFilter(data) {

			let permission = JSON.parse(localStorage.getItem("bp"));
			let alertBookExcept = permission["frontPermission"]["alert"]["bookExcept"];
			
			let result = data.filter(item => (!alertBookExcept.includes(item.book)));

			return result;
		},
		alertPermissionCheck(data) {
			let book = "";
			let alert_name = data.alert_name;
			if (alert_name == "big_lot" || alert_name == "profit_taker" || 
				alert_name == "locking_position" || alert_name == "large_exposure") {
				book = data.book;
			} else if (alert_name == "mandate") {
				book = data.bookOrGoup;
			} else if (alert_name == "trade_on_credit") {
				return false
			}

			let permission = JSON.parse(localStorage.getItem("bp"));
			let alertBookExcept = permission["frontPermission"]["alert"]["bookExcept"];
			
			// console.log("🚀 ~ file: alert.js ~ line 12 ~ alertPermissionCheck ~ alertBookExcept", alertBookExcept)
			
			if (alertBookExcept.length == 0) return true;
			if (book == null) return true;
			if (alertBookExcept.includes(book)) return false;
			return true;
		},
		alertGlobalSwitch() {
			let data = localStorage.getItem("globalNotificationAlert");
			
			if (data == "true") return true;
			return false;
		},
		alertMsg(alertName, data) {
			let msg = " " + data[0].alert_name.replace("_", " ").toUpperCase() + " - " + data[0].broker.replace("_", " ").toUpperCase() + "\n"; 
			let count = 0;
			for (let item in data[0]) {
				if (this.alertNotificationShowItem[alertName][item]) {
					let displayValue = data[0][item];
					
					if (!isNaN(data[0][item]) && item != 'login' && item != 'order' && item != 'orderTicket') {
						displayValue = displayValue.toFixed(2);
					}
					
					msg += " " + item.replace("_", "").toUpperCase() + ": " + displayValue;
					
					if (count%2 == 0) {
						msg += "\n";
					}
					count += 1;
				}
			}
			return msg;
		}
	}
}