<template>
	<div class="home">
		<v-card>
			<v-container fluid pt-0 mt-0>
				<v-row class="blue-grey darken-4">
					<Breadcrumbs/>
				</v-row>
				
				<v-row><v-col cols="12"></v-col></v-row>
				
				<v-row>
					<v-col cols="4">
						<v-menu offset-y v-if="root">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="warning" dark v-bind="attrs" v-on="on">
									{{ selectedBroker }}
								</v-btn>
							</template>
							<v-list>
								<v-list-item v-for="(item, index) in this.brokers" :key="index" 
									@click="changeBroker(item.broker, item.broker_id)">
									<v-list-item-title>{{ item.broker }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="3" v-for="item in alertChannel" :key="item" v-if="item != 'tick_check' && frontPermission.includes(item)">
						<DatatableAlert ref="child" v-if="loadTable" :name="item" 
							:dataSource="alertDataSource[item]" 
							:itemsPerPage="itemsPerPage" :broker="selectedBroker" :brokerId="selectedBrokerId" 
							@openConfig="openConfig" />
					</v-col>
				</v-row>
				
				<v-row>
					<v-col lg="12" md="12" xs="12" cols="12">
						<DatatableAlert ref="child" v-if="loadTable && frontPermission.includes('tick_check')" 
							:name="'tick_check'" :dataSource="alertDataSource['tick_check']" 
							:itemsPerPage="itemsPerPage" :broker="selectedBroker" :brokerId="selectedBrokerId" 
							@openConfig="openConfig" />
					</v-col>
				</v-row>
				
				<!-- <JsonEditor ref="jsonEditor" v-model="dialog.config"/> -->
				
				<!-- <codemirror  /> -->
				<v-dialog v-model="dialog.visible" max-width="1000">
					<v-card>
						<v-card-title class="headline">
							{{ dialog.title }}
						</v-card-title>
						
						<v-card-actions>
							<v-row>
								<v-col lg="12" md="12" xs="12" cols="12">
									<JsonEditor ref="jsonEditor" v-model="dialog.config" v-if="dialog.loadJson" />
								</v-col>
							</v-row>
						</v-card-actions>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="green darken-1" text @click="dialog.visible = false">Cancel</v-btn>
							<v-btn color="green darken-1" text @click="configHandler().save()" :loading="dialog.loading">
								<span>Save</span>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

			</v-container>
		</v-card>
	</div>
</template>
<script>
import * as apiAlertConfig from "@components/api/pk/alert-config";
import DatatableAlert from "./components/ComponentDatatableAlert";
import * as apiBook from "@components/api/pk/book";
import * as apiAlertData from "@components/api/pk/alert-data";
import * as apiAlertChannel from "@components/api/pk/alert-channel";
import { mapState } from "vuex";
import Breadcrumbs from "@components/Breadcrumbs";
import MandateTable from "@components/alert/MandateTable";
import TradeOnCreditTable from "@components/alert/TradeOnCreditTable";
import FastTradeTable from "@components/alert/FastTradeTable";
import BigLotTable from "@components/alert/BigLotTable";
import pako from "pako";
import ToastMsg from "./components/ComponentsToastMsg";
import { alert } from "@components/mixins/alert";
import { helper } from "@components/mixins/helper";
import ConfigBigLot from "./components/ComponentConfigBigLot";
import ConfigMandate from "./components/ComponentConfigMandate";
import JsonEditor from '@components/ComponentJsonEditor';

export default {
	components: {
		Breadcrumbs, MandateTable, TradeOnCreditTable, FastTradeTable, BigLotTable,
		DatatableAlert, ToastMsg, ConfigBigLot, ConfigMandate, JsonEditor
	},
	mixins: [alert, helper],
	data() {
		return {
			frontPermission: [],
			disbleTest: false,
			dataBiglot: [],
			showTest: false,
			listBook: ['B','GS','H','B1706','Z'],
			selectedBrokerId: -1,
			selectedBroker: "",
			brokers: [],
			alertChannel: [],
			alertData: [],
			alertHeader: [],
			alertDataSource: [],
			itemsPerPage: 5,
			loadTable: false,
			root: -1,
			dialog: {
				visible: false,
				name: "",
				config: {},
				loadJson: false,
				loading: false,
				broker: "",
			},
			codeOptions: { 
				tabSize: 4,
				styleActiveLine: true,
				lineNumbers: true,
				line: true,
				foldGutter: true,
				styleSelectedText: true,
				mode: 'text/javascript',
				keyMap: "sublime",
				matchBrackets: true,
				showCursorWhenSelecting: true,
				theme: "monokai",
				extraKeys: { "Ctrl": "autocomplete" },
				hintOptions:{
					completeSingle: false
				}
			}
		}
	},
	computed: {
		...mapState([
			'userLevel',
			'brokerId',
			'snackmsg',
			'snackbar',
			'snackcolor',
		])
	},
	watch: {
		showTest(nv, ov) {

		},
		"dialog.visible"(nv, ov) {
			if (nv == false) {
				this.$store.state.snackbar = false;
			}
		},
		selectedBroker(nv, ov) {
			
			// this.loadAlertData();
			this.loadTable = false;
			
			// remove all listener when change broker
			this.$store.state.socket.removeAllListeners();
			
			// // remove all listener when change broker
			// this.$store.state.socket.removeAllListeners();
			
			// // establish socket connection
			// let socket = this.$store.state.socket;
			// socket.on('connect', msg => {
			// 	console.log("Welcome Msg: ", msg);
			// })

			// // loop to subscribe channel
			// for (let i = 0; i < this.alertChannel.length; i++) {
				
            //     let isExcept = this.filterChannel(this.alertChannel[i]);
            //     if (isExcept) continue;
				
			// 	let selectedChannel = this.selectedBroker + "-AlertsChannel:" + this.alertChannel[i];

			// 	let self = this

			// 	socket.on(selectedChannel, data => {
			// 	   data = JSON.parse(data)
			// 	   let newData = data.map(el => {
			// 			let custom = el.custom
			// 			el = Object.assign(el, custom)
			// 			delete el.custom
			// 			return el
			// 		});

			// 		let alertName = data[0].alert_name
					
			// 		self.appendAlert(data, alertName);
			// 		// newData.forEach(element => {
			// 			// self.alertDataSource[alertName].unshift(element)
			// 		// });

			// 		if (self.alertGlobalSwitch() && self.alertPermissionCheck(data[0])) {
			// 			let msg = self.alertMsg(alertName, data);
						
			// 			if (alertName != "trade_on_credit") {
			// 				self.$toast(msg, {
			// 					toastClassName: "custom-toast", position: "top-right", timeout: 5000, closeOnClick: false,
			// 					pauseOnFocusLoss: true, pauseOnHover: true, draggable: true,
			// 					draggablePercent: 0.6, showCloseButtonOnHover: true, hideProgressBar: false,
			// 					closeButton: "button", icon: true, rtl: false,
			// 					onClick: () => self.$router.push({path: 'alert'})
			// 				});
			// 			}
			// 		}
			// 	})
			// }

			this.$nextTick(() => {
				this.loadTable = true;
			})
		}
	},
	methods: {
		appendAlert(data, name) {
			let passedData = {data: data, name: name};
			this.$refs.child.$emit("appendAlert", passedData);
		},
		openConfig(name, broker) {
			this.dialog.visible = true;
			this.dialog.title = broker + " - Config for " + name;
			this.dialog.name = name;
			this.dialog.broker = broker;
			this.dialog.loadJson = false;
			this.configHandler().load(broker, name);

			console.log("🚀 ~ file: Alarm.vue ~ line 179 ~ openConfig ~ name", name)
			console.log("🚀 ~ file: Alarm.vue ~ line 179 ~ openConfig ~ broker", broker)
		},
		configHandler() {
			let self = this;

			return {
				load(broker, name) {
					let params = {broker: broker, alert: name};
					self.dialog.loading = false;
					self.dialog.config = {};
					apiAlertConfig.fetch(params).then(res => {
						self.$nextTick(() => {
							self.dialog.config = res.data;
							console.log("Mother Fucker: ", typeof self.dialog.config);
							self.dialog.loadJson = true;
						})
					})
				},
				save() {
					let configParam = {};
					try {
						if (typeof self.dialog.config === 'object' && self.dialog.config !== null) {
							configParam = self.dialog.config;
						} else {
							JSON.parse(self.dialog.config);
							configParam = JSON.parse(self.dialog.config);
						}
					} catch (e) {
						console.log(e.toString(), " ===> ", self.dialog.config);
						self.$store.state.snackbar = true;
						self.$store.state.snackcolor = "red";
						self.$store.state.snackmsg = self.dialog.name + ": Invalid Json";

						return;
					}

					let params = {broker: self.dialog.broker, alert: self.dialog.name, config: configParam};
					
					console.log(self.dialog.config);

					self.dialog.loading = true;
					apiAlertConfig.create(params).then(res => {
						setTimeout(() => {
							self.$store.state.snacktimeout = 2500;
							self.$store.state.snackbar = true;
							self.$store.state.snackcolor = "success";
							self.$store.state.snackmsg = "Config " + self.dialog.name + " Update Successfully";
						}, 1450);
						setTimeout(() => {
							self.loading = false;
							self.dialog.visible = false;
						}, 2000);
					})
				}
			}
		},
		loadAlertData() {
			let self = this;
			let param = {broker_id: self.selectedBrokerId};
			self.loadTable = false;
			self.$store.state.overlay = true;
			// console.log(param);
			apiAlertData.fetch(param).then(res => {
				// self.alertData = res.data;
				self.alertHeader = res.data["data_header"];
				if (res.data["data_alert"] == null) {
					self.alertDataSource = {};
				} else {
					self.alertDataSource = res.data["data_alert"];
				}
				// console.log("🚀 ~ file: Alarm.vue ~ line 155 ~ apiAlertData.fetch ~ self.alertDataSource", self.alertDataSource)
				// console.log(JSON.stringify(res.data["data_header"]));
				self.$nextTick(() => {
					self.loadTable = true;
					self.$store.state.overlay = false;
				})
			})
		},
		loadAlertChannel() {
			let self = this;
			return apiAlertChannel.fetch().then(res => {
				self.alertChannel = res.data;
			})
		},
		unzip(data) {
			// Decode base64 (convert ascii to binary)
			let strData     = atob(data);

			// Convert binary string to character-number array
			let charData    = strData.split('').map(function(x){return x.charCodeAt(0);});

			// Turn number array into byte-array
			let binData     = new Uint8Array(charData);

			// Pako magic
			let newData        = pako.inflate(binData);

			// Convert gunzipped byteArray back to ascii string:
			let result     = String.fromCharCode.apply(null, new Uint16Array(newData));

			return result;
		},
		establishWs() {},
		changeBroker(broker, brokerId) {
			this.selectedBroker = broker
			this.selectedBrokerId = brokerId
		}
	},
	mounted() {
		this.establishWs();
	},
	async created() {
		await this.loadAlertChannel();
		let data = JSON.parse(localStorage.getItem("bp"))
		this.frontPermission = data["frontPermission"]["alert"]["channel"];;
		let brokerArr = data.broker;
		if (brokerArr.length > 1)  {
			this.brokers = brokerArr
			console.log("🚀 ~ file: Home.vue ~ line 592 ~ created ~ this.brokers", this.brokers)
			this.selectedBrokerId = 1;
			this.selectedBroker = this.brokers[0].broker;
			this.root = 1;
		} else { this.brokers = brokerArr }
		let brokerId = data.broker[0].broker_id;
		let broker = data.broker[0].broker;
		this.selectedBrokerId = brokerId;
		this.selectedBroker = broker;
		// this.loadAlertData();

		// this.loadBookGroup();
		// this.$watch("selectedBroker");
	},
	destroyed() {
	}
}
</script>