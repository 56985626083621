<template lang="">
    <div>
        <v-row>
            <v-col lg="12" md="12" xs="12" cols="12">{{ title }}</v-col>
            <v-col lg="12" md="12" xs="12" cols="12">
                <v-row v-if="name != 'tick_check'">
                    <v-col lg="10" md="10" xs="12" cols="12">
                        <v-text-field class="mt-n2 mb-2" v-model="search" append-icon="mdi-search" 
                            label="Search" single-line hide-details>
                        </v-text-field>
                    </v-col>
                    
                    <v-col lg="1" md="1" xs="12" cols="12">
                        <download-excel :data="Object.values(apiDataSource)" :fields="csvFields" :name="broker + '_' + name ">
                            <v-btn color="green" class="accent-4" icon small>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon  color="green" dark v-bind="attrs" v-on="on" dense>
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </template>
                                    <span>Download As Excel</span>
                                </v-tooltip>
                            </v-btn>
                        </download-excel>
                    </v-col>
                    
                    <v-col lg="1" md="1" xs="12" cols="12">
                        <v-btn color="primary" class="accent-4" icon small v-on:click="openConfig()">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" dark v-bind="attrs" v-on="on"  dense>mdi-cog</v-icon>
                                </template>
                                <span>Settings</span>
                            </v-tooltip>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="name == 'tick_check'">
                    <v-col lg="11" md="11" xs="12" cols="12">
                        <v-text-field class="mr-4 mt-n2 mb-2" v-model="search" append-icon="mdi-search" 
                            label="Search" single-line hide-details>
                        </v-text-field>
                    </v-col>
                    
                    <v-col lg="1" md="1" xs="12" cols="12">
                        <download-excel style="display:inline;" :data="Object.values(apiDataSource)" :fields="csvFields" :name="broker + '_' + name ">
                            <v-btn color="#08591c" icon small class="accent-4 mr-5">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="green" dark v-bind="attrs" v-on="on" dense>
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </template>
                                    <span>Download As Excel</span>
                                </v-tooltip>
                            </v-btn>
                        </download-excel>
                        <v-btn color="primary" class="accent-4" icon small v-on:click="openConfig()">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" dark v-bind="attrs" v-on="on"  dense>mdi-cog</v-icon>
                                </template>
                                <span>Settings</span>
                            </v-tooltip>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col lg="12" md="12" xs="12" cols="12">
                <v-data-table dense :search="search" :headers="dataHeaders" :items="apiDataSource" class="elevation-1" 
                    :items-per-page="itemsPerPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :loading="loading"
                    loading-text="Loading... Please wait">
                    
                    <template v-slot:item.balance="{ item }">
                        {{ item.balance.toFixed(2) }}
                    </template>
                    
                    <template v-slot:item.profit="{ item }">
                        {{ item.profit.toFixed(2) }}
                    </template>
                    
                    <template v-slot:totalVolume.profit="{ item }">
                        {{ (item.totalVolume/100).toFixed(2) }}
                    </template>

                    <template v-slot:volume.profit="{ item }" v-if="name == 'same_direction'">
                        {{ (item.volume/100).toFixed(2) }}
                    </template>
                    
                    
                    <!-- <template v-slot:item.volume="props" v-if="name == 'big_lot'">
                        <v-tooltip right color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ (props.item.volume/100).toFixed(2) }}
                                </span>
                            </template>
                            <v-list dense>
                                <v-subheader>Details</v-subheader>
                                <v-list-item v-for="(detail, i) in props.item" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="i"></v-list-item-title>
                                        <v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </template>
                    -->

                    <template v-slot:item.currentValue="props" v-if="name == 'mandate'">
                        <v-tooltip right color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ props.item.currentValue.toFixed(2) }}
                                </span>
                            </template>
                            <v-list dense>
                                <v-subheader>Details</v-subheader>
                                <v-list-item v-for="(detail, i) in props.item" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="i"></v-list-item-title>
                                        <v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.profitToday="props" v-if="name == 'profit_taker'">
                        <v-tooltip right color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ props.item.profitToday.toFixed(2) }}
                                </span>
                            </template>
                            <v-list dense>
                                <v-subheader>Details</v-subheader>
                                <v-list-item v-for="(detail, i) in props.item" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="i"></v-list-item-title>
                                        <v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.equity="props" v-if="name == 'trade_on_credit'">
                        <v-tooltip right color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ props.item.equity.toFixed(2) }}
                                </span>
                            </template>
                            <v-list dense>
                                <v-subheader>Details</v-subheader>
                                <v-list-item v-for="(detail, i) in props.item" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="i"></v-list-item-title>
                                        <v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.lockingPosition="props" v-if="name == 'locking_position'">
                        <v-tooltip right color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ props.item.lockingPosition.toFixed(2) }}
                                </span>
                            </template>
                            <v-list dense>
                                <v-subheader>Details</v-subheader>
                                <v-list-item v-for="(detail, i) in props.item" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="i"></v-list-item-title>
                                        <v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.exposure="props" v-if="name == 'large_exposure'">
                        <v-tooltip right color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ props.item.exposure.toFixed(2) }}
                                </span>
                            </template>
                            <v-list dense>
                                <v-subheader>Details</v-subheader>
                                <v-list-item v-for="(detail, i) in props.item" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="i"></v-list-item-title>
                                        <v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.volume="props" v-if="name == 'fast_trade' || name == 'big_lot'">
                        <v-tooltip right color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" v-if="props.item.volume != null">
                                    {{ (props.item.volume/100).toFixed(2) }}
                                </span>
                            </template>
                            <v-list dense>
                                <v-subheader>Details</v-subheader>
                                <v-list-item v-for="(detail, i) in props.item" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="i"></v-list-item-title>
                                        <v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.orderTicket="props" v-if="name == 'watch_list'">
                        <v-tooltip right color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ props.item.orderTicket }}
                                </span>
                            </template>
                            <v-list dense>
                                <v-subheader>Details</v-subheader>
                                <v-list-item v-for="(detail, i) in props.item" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="i"></v-list-item-title>
                                        <v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </template>
                    
                    <template v-slot:item.event="props" v-if="name == 'same_direction'">
                        <v-tooltip right color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ props.item.event }}
                                </span>
                            </template>
                            <v-list dense>
                                <v-subheader>Details</v-subheader>
                                <v-list-item v-for="(detail, i) in props.item" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="i"></v-list-item-title>
                                        <v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.volumeToday="props" v-if="name == 'volume_alert'">
                        <v-tooltip right color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ props.item.volumeToday.toFixed(2) }}
                                </span>
                            </template>
                            <v-list dense>
                                <v-subheader>Details</v-subheader>
                                <v-list-item v-for="(detail, i) in props.item" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="i"></v-list-item-title>
                                        <v-list-item-subtitle twoLine v-html="detail"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-tooltip>
                    </template>

                </v-data-table>

            </v-col>
        </v-row>
    </div>
</template>
<script>
import {mapState} from "vuex";
import * as apiAlertData from "@components/api/pk/alert-data";
import { alert } from "@components/mixins/alert";

export default {
    components: {},
	mixins: [alert],
    props: {
        name: "",
        dataSource: null,
        itemsPerPage: null,
        broker: null,
        headers: null,
        brokerId: null
    },
    data() {
        return {
            sortBy: "time",
            sortDesc: true,
            search: "",
            loading: false,
            reloadTable: false,
            title: "",
            dataHeaders: [],
            csvFields: {},
            apiDataSource: []
        }
    },
    computed: {
        ...mapState([
            "alertHeader"
        ])
    },
    methods: {
        openConfig() {
            console.log("Access ", this.name, " => ", this.broker);
            this.$emit("openConfig", this.name, this.broker);
        },
		loadAlertData() {
			let self = this;

			let param = {broker_id: self.brokerId, alert: self.name};

            self.loading = true;

			apiAlertData.fetch(param).then(res => {
                
				if (res.data["data_alert"] == null) {
					self.apiDataSource = {};
				} else {
					// self.alertDataSource = res.data["data_alert"];
                    self.apiDataSource = self.alertPermissionDataFilter(res.data["data_alert"][self.name]);
                }
                
                self.loading = false;
				
                self.$nextTick(() => {})
			})
		},
    },
    mounted() {
        this.dataHeaders = this.alertHeader[this.name];

        for (let i = 0; i < this.dataHeaders.length; i++) {
            this.csvFields[this.dataHeaders[i].text] = this.dataHeaders[i].value;
        }

        this.loadAlertData();

        if (this.name == "big_lot") this.title = "Big Lot";
        if (this.name == "mandate") this.title = "Mandate";
        if (this.name == "profit_taker") this.title = "Profit Taker";
        if (this.name == "locking_position") this.title = "Locking Position";
        if (this.name == "large_exposure") this.title = "Large Exposure";
        if (this.name == "trade_on_credit") this.title = "Trade On Credit";
        if (this.name == "fast_trade") this.title = "Fast Trade";
        if (this.name == "watch_list") this.title = "Watchlist";
        if (this.name == "tick_check") this.title = "Tick Check";
        if (this.name == "same_direction") this.title = "Same Direction";
        if (this.name == "volume_alert") this.title = "Volume Alert";

        // establish socket connection
        let socket = this.$store.state.socket;

        console.log("🚀 ~ file: alert ~ line 369 ~ mounted ~ socket", socket)

        socket.on('connect', msg => {
            console.log("Welcome Msg: ", msg);
        })

        let isExcept = this.filterChannel(this.name);
        
        if (!isExcept) {
            
            let selectedChannel = this.broker + "-AlertsChannel:" + this.name;

            // console.log("🚀 ~ file: alert ~ line 378 ~ mounted ~ selectedChannel", selectedChannel)

            let self = this;

            // console.log("Fire: ", selectedChannel);

            socket.on(selectedChannel, data => {
                data = JSON.parse(data);
                
                // console.log("New Data Coming: ", data);

                let newData = data.map(el => {
                    let custom = el.custom;
                    el = Object.assign(el, custom);
                    delete el.custom;
                    return el;
                });

                let alertName = data[0].alert_name;
                
                // console.log(self.apiDataSource);
                
                newData.forEach(element => {
                    self.apiDataSource.unshift(element);
                });

                if (self.alertGlobalSwitch() && self.alertPermissionCheck(data[0])) {
                    let msg = self.alertMsg(alertName, data);
                    
                    if (alertName != "trade_on_credit") {
                        // self.$toast(msg, {
                        //     toastClassName: "custom-toast", position: "top-right", timeout: 5000, closeOnClick: false,
                        //     pauseOnFocusLoss: true, pauseOnHover: true, draggable: true,
                        //     draggablePercent: 0.6, showCloseButtonOnHover: true, hideProgressBar: false,
                        //     closeButton: "button", icon: true, rtl: false,
                        //     onClick: () => self.$router.push({path: 'alert'})
                        // });
                    }
                }
            })
            console.log("Subscribe Done");
        }
        
        // this.$on("appendAlert", (data) => {
            
        //     data['data'].forEach(element => {
        //         console.log(element.alert_name, " => ", this.name);
        //         if (this.name == element.alert_name) {
        //             console.log("New Alert Name: ", element.alert_name);
        //             this.apiDataSource.unshift(element)
        //         }
        //     });
        //     console.log(this.apiDataSource);
        // })
    }
}
</script>
<style lang="">
    
</style>