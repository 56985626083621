<template>
	<div>
		<v-row>
			<v-col cols="12" sm="12" md="12" lg="12" xl="6">
				<v-text-field label="Lot" hide-details="auto"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>
<script>
export default {
	props: {
		name: null,
		config: null
	},
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>