var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-container',[_c('v-row',[_c('v-badge',{attrs:{"content":_vm.fastTradeCount,"value":_vm.fastTradeCount,"color":"red","inline":""}},[_vm._v(" Fast Trade ")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"orange"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cog-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Fast Trade Setting")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"hint":"x 100","persistent-hint":_vm.isPersistentHint,"label":"Lot Threshold >= "},model:{value:(
												_vm.fastTradeSetting.lotThreshold
											),callback:function ($$v) {_vm.$set(_vm.fastTradeSetting, "lotThreshold", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tfastTradeSetting.lotThreshold\n\t\t\t\t\t\t\t\t\t\t\t"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Second Threshold =< "},model:{value:(
												_vm.fastTradeSetting.timeThreshold
											),callback:function ($$v) {_vm.$set(_vm.fastTradeSetting, "timeThreshold", $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tfastTradeSetting.timeThreshold\n\t\t\t\t\t\t\t\t\t\t\t"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveSetting}},[_vm._v("Save")])],1)],1)],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"green"},on:{"click":_vm.toCSV}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-microsoft-excel")])],1),_c('v-btn',{staticClass:"gray accent-4 ml-2",style:(_vm.isSubscribe
							? 'background-color: red;'
							: 'background-color: gray;'),attrs:{"x-small":"","fab":"","isSubscribe":_vm.isSubscribe,"loading":_vm.isSubscribeLoading},on:{"click":_vm.subscribe}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.isSubscribe ? "mdi-email-check" : "mdi-email"))])],1)],1),_c('v-row',[_c('v-text-field',{staticClass:"mr-5 mb-4",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.deals,"items-per-page":_vm.itemsPerPage,"sort-by":_vm.tableSort,"sort-desc":_vm.tableSortOrder,"fixed-header":"","height":"288","hide-default-footer":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.volume",fn:function(props){return [_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.volume)+" ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Details")]),_vm._l((_vm.infos[props.item.order]),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}}),_c('v-list-item-subtitle',{attrs:{"twoLine":""},domProps:{"innerHTML":_vm._s(item.value)}})],1)],1)})],2)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }