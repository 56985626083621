var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"lg":"12","md":"12","xs":"12","cols":"12"}},[_vm._v(_vm._s(_vm.title))]),_c('v-col',{attrs:{"lg":"12","md":"12","xs":"12","cols":"12"}},[(_vm.name != 'tick_check')?_c('v-row',[_c('v-col',{attrs:{"lg":"10","md":"10","xs":"12","cols":"12"}},[_c('v-text-field',{staticClass:"mt-n2 mb-2",attrs:{"append-icon":"mdi-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"lg":"1","md":"1","xs":"12","cols":"12"}},[_c('download-excel',{attrs:{"data":Object.values(_vm.apiDataSource),"fields":_vm.csvFields,"name":_vm.broker + '_' + _vm.name}},[_c('v-btn',{staticClass:"accent-4",attrs:{"color":"green","icon":"","small":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":"","dense":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-microsoft-excel ")])]}}],null,false,1070070425)},[_c('span',[_vm._v("Download As Excel")])])],1)],1)],1),_c('v-col',{attrs:{"lg":"1","md":"1","xs":"12","cols":"12"}},[_c('v-btn',{staticClass:"accent-4",attrs:{"color":"primary","icon":"","small":""},on:{"click":function($event){return _vm.openConfig()}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","dense":""}},'v-icon',attrs,false),on),[_vm._v("mdi-cog")])]}}],null,false,2555996651)},[_c('span',[_vm._v("Settings")])])],1)],1)],1):_vm._e(),(_vm.name == 'tick_check')?_c('v-row',[_c('v-col',{attrs:{"lg":"11","md":"11","xs":"12","cols":"12"}},[_c('v-text-field',{staticClass:"mr-4 mt-n2 mb-2",attrs:{"append-icon":"mdi-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"lg":"1","md":"1","xs":"12","cols":"12"}},[_c('download-excel',{staticStyle:{"display":"inline"},attrs:{"data":Object.values(_vm.apiDataSource),"fields":_vm.csvFields,"name":_vm.broker + '_' + _vm.name}},[_c('v-btn',{staticClass:"accent-4 mr-5",attrs:{"color":"#08591c","icon":"","small":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":"","dense":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-microsoft-excel ")])]}}],null,false,1070070425)},[_c('span',[_vm._v("Download As Excel")])])],1)],1),_c('v-btn',{staticClass:"accent-4",attrs:{"color":"primary","icon":"","small":""},on:{"click":function($event){return _vm.openConfig()}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","dense":""}},'v-icon',attrs,false),on),[_vm._v("mdi-cog")])]}}],null,false,2555996651)},[_c('span',[_vm._v("Settings")])])],1)],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"12","md":"12","xs":"12","cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","search":_vm.search,"headers":_vm.dataHeaders,"items":_vm.apiDataSource,"items-per-page":_vm.itemsPerPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.balance.toFixed(2))+" ")]}},{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profit.toFixed(2))+" ")]}},{key:"totalVolume.profit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.totalVolume/100).toFixed(2))+" ")]}},(_vm.name == 'same_direction')?{key:"volume.profit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.volume/100).toFixed(2))+" ")]}}:null,(_vm.name == 'mandate')?{key:"item.currentValue",fn:function(props){return [_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.currentValue.toFixed(2))+" ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Details")]),_vm._l((props.item),function(detail,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i)}}),_c('v-list-item-subtitle',{attrs:{"twoLine":""},domProps:{"innerHTML":_vm._s(detail)}})],1)],1)})],2)],1)]}}:null,(_vm.name == 'profit_taker')?{key:"item.profitToday",fn:function(props){return [_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.profitToday.toFixed(2))+" ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Details")]),_vm._l((props.item),function(detail,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i)}}),_c('v-list-item-subtitle',{attrs:{"twoLine":""},domProps:{"innerHTML":_vm._s(detail)}})],1)],1)})],2)],1)]}}:null,(_vm.name == 'trade_on_credit')?{key:"item.equity",fn:function(props){return [_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.equity.toFixed(2))+" ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Details")]),_vm._l((props.item),function(detail,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i)}}),_c('v-list-item-subtitle',{attrs:{"twoLine":""},domProps:{"innerHTML":_vm._s(detail)}})],1)],1)})],2)],1)]}}:null,(_vm.name == 'locking_position')?{key:"item.lockingPosition",fn:function(props){return [_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.lockingPosition.toFixed(2))+" ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Details")]),_vm._l((props.item),function(detail,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i)}}),_c('v-list-item-subtitle',{attrs:{"twoLine":""},domProps:{"innerHTML":_vm._s(detail)}})],1)],1)})],2)],1)]}}:null,(_vm.name == 'large_exposure')?{key:"item.exposure",fn:function(props){return [_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.exposure.toFixed(2))+" ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Details")]),_vm._l((props.item),function(detail,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i)}}),_c('v-list-item-subtitle',{attrs:{"twoLine":""},domProps:{"innerHTML":_vm._s(detail)}})],1)],1)})],2)],1)]}}:null,(_vm.name == 'fast_trade' || _vm.name == 'big_lot')?{key:"item.volume",fn:function(props){return [_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(props.item.volume != null)?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s((props.item.volume/100).toFixed(2))+" ")]):_vm._e()]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Details")]),_vm._l((props.item),function(detail,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i)}}),_c('v-list-item-subtitle',{attrs:{"twoLine":""},domProps:{"innerHTML":_vm._s(detail)}})],1)],1)})],2)],1)]}}:null,(_vm.name == 'watch_list')?{key:"item.orderTicket",fn:function(props){return [_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.orderTicket)+" ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Details")]),_vm._l((props.item),function(detail,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i)}}),_c('v-list-item-subtitle',{attrs:{"twoLine":""},domProps:{"innerHTML":_vm._s(detail)}})],1)],1)})],2)],1)]}}:null,(_vm.name == 'same_direction')?{key:"item.event",fn:function(props){return [_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.event)+" ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Details")]),_vm._l((props.item),function(detail,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i)}}),_c('v-list-item-subtitle',{attrs:{"twoLine":""},domProps:{"innerHTML":_vm._s(detail)}})],1)],1)})],2)],1)]}}:null,(_vm.name == 'volume_alert')?{key:"item.volumeToday",fn:function(props){return [_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.volumeToday.toFixed(2))+" ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Details")]),_vm._l((props.item),function(detail,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(i)}}),_c('v-list-item-subtitle',{attrs:{"twoLine":""},domProps:{"innerHTML":_vm._s(detail)}})],1)],1)})],2)],1)]}}:null],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }