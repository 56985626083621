<template>
	<div>
		<v-card>
			<v-card-title>
				<v-container>
					<v-row>
						<v-badge :content="tradeOnCreditCount" :value="tradeOnCreditCount" color="red" inline>
							Credit Trading
						</v-badge>
						<v-spacer></v-spacer>
						<v-dialog v-model="dialog" max-width="500px">
							<template v-slot:activator="{ on }">
								<v-btn class="mx-2" fab dark x-small color="orange" v-on="on">
									<v-icon dark>mdi-cog-outline</v-icon>
								</v-btn>
							</template>
							<v-card :loading="isSettingLoading">
								<v-card-title>
									<span class="headline">Trade On Credit Setting</span>
								</v-card-title>
								<v-card-text>
									<v-container>
										<v-row>
											<v-col cols="12" sm="6" md="6">
												<v-text-field :persistent-hint="isPersistentHint" 
													v-model="tradeOnCreditSetting.ratio_threshold" label="Credit/Equity >= " >
												</v-text-field>
											</v-col>
										</v-row>
									</v-container>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text @click="close" >Cancel</v-btn>
									<v-btn color="blue darken-1" text @click="saveSetting">Save</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
						<v-btn class="mx-2" fab dark x-small color="green" @click="toCSV">
							<v-icon dark>mdi-microsoft-excel</v-icon>
						</v-btn>
						<v-btn class="gray accent-4 ml-2" x-small fab @click="subscribe" :isSubscribe="isSubscribe" 
							:loading="isSubscribeLoading" :style="isSubscribe? 'background-color: red;': 'background-color: gray;'">
							<v-icon dark>{{ isSubscribe ? "mdi-email-check" : "mdi-email" }}</v-icon>
						</v-btn>
					</v-row>
					<v-row>
						<v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
							hide-details class="mr-5 mb-4" width="50">
						</v-text-field>
					</v-row>
				</v-container>
			</v-card-title>
			<v-data-table :headers="headers" :items-per-page="itemsPerPage" hide-default-footer :items="accountInfos"
				:sort-by="tableSort" :sort-desc="tableSortOrder" fixed-header height="288" :search="search">
				<template v-slot:item.volume="props">
					<v-tooltip right color="transparent">
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on">
								{{ props.item.volume }}
							</span>
						</template>
						<v-list dense>
							<v-subheader>Details</v-subheader>
							<v-list-item v-for="(item, i) in infos[props.item.order]" :key="i" >
								<v-list-item-content>
									<v-list-item-title v-text="item.title" ></v-list-item-title>
									<v-list-item-subtitle twoLine v-html="item.value" ></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
// import AlarmDataService from "../../services/AlarmDataService";
// import SettingService from "../../services/SettingService";
// import PKControlService from "../../services/PKControlService";
import { mapActions, mapState } from "vuex";
export default {
	watch: {
		dialog(val) {
			val || this.close();
		}
	},
	computed: mapState({
		tradeOnCreditCount() {
			return this.accountInfos.length;
		},
		userEmail: state => state.userEmail,
		userName: state => state.userName,
		userId: state => state.userId,
		userRole: state => state.userRole,
		userInfo() {
			return {
				userName: this.userName,
				userId: this.userId,
				userRole: this.userRole
			};
		}
	}),
	beforeMount() {
		this.TradeOnCreditStreaming();
		this.getSubsribeStatus();
		this.getTradeOnCreditMonitorSetting();
	},
	beforeDestroy() {
		this.stream.cancel();
	},
	methods: {
		getSubsribeStatus() {
			let vm = this;
			SettingService.GetEmailReceiver(
				{ type: "TradeOnCreditAlarm-Receivers", email: this.userEmail },
				(err, response) => {
					if (response != null) {
						if (response.toObject().statusCode == 0) {
							vm.isSubscribe = true;
						}
					}
				}
			);
		},
		subscribe() {
			// let vm = this;
			// if (!this.isSubscribe) {
			// 	this.isSubscribeLoading = true;
			// 	let sendType = "bcc";
			// 	if (this.userRole == "admin") {
			// 		sendType = "to";
			// 	}
			// 	SettingService.SubscribeAlarm(
			// 		{
			// 			type: "TradeOnCreditAlarm-Receivers",
			// 			email: this.userEmail,
			// 			sendType: sendType
			// 		},
			// 		(err, response) => {
			// 			if (err) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: err,
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else if (response.toObject().msg == 0) {
			// 				vm.isSubscribe = true;
			// 				vm.promptInfoSnack({
			// 					text: "Already Subscribed",
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else {
			// 				vm.isSubscribe = !vm.isSubscribe;
			// 			}
			// 			vm.isSubscribeLoading = false;
			// 		}
			// 	);
			// } else {
			// 	this.isSubscribeLoading = true;
			// 	SettingService.UnsubscribeAlarm(
			// 		{
			// 			type: "TradeOnCreditAlarm-Receivers",
			// 			email: this.userEmail
			// 		},
			// 		(err, response) => {
			// 			if (err) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: err,
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else if (response.toObject().msg == 0) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: "Subscriber Not Exist",
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else {
			// 				vm.isSubscribe = !vm.isSubscribe;
			// 			}
			// 			vm.isSubscribeLoading = false;
			// 		}
			// 	);
			// }
		},
		getTradeOnCreditMonitorSetting() {
			// let vm = this;
			// SettingService.GetTradeOnCreditMonitorSetting((err, response) => {
			// 	if (err) {
			// 		vm.promptInfoSnack({
			// 			text: "Trade On Credit Empety Setting",
			// 			status: true,
			// 			color: "red"
			// 		});
			// 	} else {
			// 		vm.tradeOnCreditSetting.ratio_threshold =
			// 			Math.round(response.toObject().ratioThreshold * 10) /
			// 			10;
			// 	}
			// });
		},
		toCSV() {
			this.$ToCSV("TradeOnCredit", this.csvHeader, this.accountInfos);
		},
		close() {
			this.dialog = false;
		},
		async saveSetting() {
			// let vm = this;
			// this.isSettingLoading = true;
			// try {
			// 	await SettingService.SetTradeOnCreditMonitorSetting({
			// 		...this.tradeOnCreditSetting,
			// 		...this.userInfo
			// 	});
			// 	await PKControlService.UpdatePKSetting(
			// 		"TradeOnCredit",
			// 		this.userInfo
			// 	);
			// 	this.isSettingLoading = false;
			// 	this.close();
			// 	vm.promptInfoSnack({
			// 		text: "Change Successfully",
			// 		status: true,
			// 		color: "success"
			// 	});
			// } catch (err) {
			// 	this.isSettingLoading = false;
			// 	this.close();
			// 	vm.promptInfoSnack({ text: err, status: true, color: "red" });
			// }
		},
		TradeOnCreditStreaming() {
			// let vm = this;
			// this.stream = AlarmDataService.ConstructTradeOnCreditStream({
			// 	...vm.userInfo
			// });
			// this.stream.on("status", function(status) {
			// 	console.log(status.code);
			// 	console.log(status.details);
			// 	console.log(status.metadata);
			// });
			// this.stream.on("end", function(end) {
			// 	console.log(end);
			// 	// stream end signal
			// });
			// this.stream.on("data", function(response) {
			// 	if (response != null) {
			// 		let accountInfos = response.toObject();
			// 		vm.accountInfos = accountInfos.accountInfosList.map(x => {
			// 			x.balance = x.balance.toFixed(2);
			// 			x.credit = x.credit.toFixed(2);
			// 			return x;
			// 		});
			// 	}
			// });
		},
		promptInfoSnack(message) {
			this.$store.dispatch("alarmCenter/toggleInfoSnack", message);
		},
		concstructDetailObject(trade) {
			let result = [
				{ title: "Order", value: trade.order },
				{ title: "action", value: trade.action },
				{ title: "Price Open", value: trade.openPrice },
				{ title: "Time Open", value: trade.timeCreate },
				{ title: "Price Close", value: trade.priceClose },
				{ title: "Time Close", value: trade.timeClose },
				{ title: "PNL", value: trade.pnl }
			];
			return result;
		}
	},
	data() {
		return {
			infos: {},
			accountInfos: [],
			stream: {},
			dialog: false,
			isSubscribe: false,
			isSubscribeLoading: false,
			isSettingLoading: false,
			isPersistentHint: true,
			tableSort: ["login", "balance"],
			tableSortOrder: [true, false],
			itemsPerPage: -1,
			search: "",
			tradeOnCreditSetting: {
				ratio_threshold: 0
			},
			csvHeader: {
				login: "Login",
				book: "Book",
				balance: "Balance",
				credit: "Credit"
			},
			headers: [
				{
					text: "Login",
					align: "start",
					value: "login"
				},
				{ text: "Book", value: "book" },
				{ text: "Balance", value: "balance" },
				{ text: "Credit", value: "credit" }
			]
		};
	}
};
</script>
