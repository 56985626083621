<template>
	<div>
		<v-card>
			<v-card-title>
				<v-container>
					<v-row>
						<v-badge :content="mandateCount" :value="mandateCount" color="red" inline>
							Mandate Alert
						</v-badge>
						<v-spacer></v-spacer>

						<v-dialog v-model="dialog" max-width="500px">
							<template v-slot:activator="{ on }">
								<v-btn class="mx-2" fab dark x-small color="orange" v-on="on">
									<v-icon dark>mdi-cog-outline</v-icon>
								</v-btn>
							</template>
							<v-card :loading="isSettingLoading">
								<v-card-title>
									<span class="headline">Mandate Setting</span>
								</v-card-title>
								<v-card-text>
									<v-container>
										<v-row>
											<v-col cols="12" sm="6" md="6">
												<v-text-field hint="x 100" :persistent-hint="isPersistentHint" 
													v-model="mandateSetting.lotThreshold" label="Net Position >= ">
												</v-text-field>
											</v-col>
											<v-col cols="12" sm="6" md="6">
												<v-text-field hint="" :persistent-hint="isPersistentHint" 
													v-model="mandateSetting.floatingThreshold" label="PNL >= ">
												</v-text-field>
											</v-col>
										</v-row>
									</v-container>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
									<v-btn color="blue darken-1" text @click="saveSetting">Save</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
						<v-btn class="mx-2" fab dark x-small color="green" @click="toCSV">
							<v-icon dark>mdi-microsoft-excel</v-icon>
						</v-btn>
						<v-btn class="gray accent-4 ml-2" x-small fab @click="subscribe" 
							:isSubscribe="isSubscribe" :loading="isSubscribeLoading" 
							:style="isSubscribe? 'background-color: red;': 'background-color: gray;'">
							<v-icon dark>{{ isSubscribe ? "mdi-email-check" : "mdi-email" }}</v-icon>
						</v-btn>
					</v-row>
					<v-row>
						<v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
							hide-details class="mr-5 mb-4" width="50">
						</v-text-field>
					</v-row>
				</v-container>
			</v-card-title>
			<v-data-table :headers="headers" :items-per-page="itemsPerPage" hide-default-footer :items="mandates"
				:sort-by="tableSort" :sort-desc="tableSortOrder" fixed-header height="288" :search="search">
				<template v-slot:item.volume="props">
					<v-tooltip right color="transparent">
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on">
								{{ props.item.volume }}
							</span>
						</template>
						<v-list dense>
							<v-subheader>Details</v-subheader>
							<v-list-item v-for="(item, i) in infos[props.item.order]" :key="i">
								<v-list-item-content>
									<v-list-item-title v-text="item.title" ></v-list-item-title>
									<v-list-item-subtitle twoLine v-html="item.value" ></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
// import AlarmDataService from "../../services/AlarmDataService";
// import SettingService from "../../services/SettingService";
// import PKControlService from "../../services/PKControlService";

import { mapActions, mapState } from "vuex";
export default {
	watch: {
		dialog(val) {
			val || this.close();
		}
	},
	computed: mapState({
		mandateCount() {
			return this.mandates.length;
		},
		userEmail: state => state.userEmail,
		userName: state => state.userName,
		userId: state => state.userId,
		userRole: state => state.userRole,
		userInfo() {
			return {
				userName: this.userName,
				userId: this.userId,
				userRole: this.userRole
			};
		}
	}),
	beforeMount() {
		this.MandateStreaming();
		this.getSubsribeStatus();
		this.getMandateMonitorSetting();
	},
	beforeDestroy() {
		this.stream.cancel();
	},
	methods: {
		getSubsribeStatus() {
			// let vm = this;
			// SettingService.GetEmailReceiver(
			// 	{ type: "MandateAlarm-Receivers", email: this.userEmail },
			// 	(err, response) => {
			// 		if (response != null) {
			// 			console.log(response.toObject());
			// 			if (response.toObject().statusCode == 0) {
			// 				vm.isSubscribe = true;
			// 			}
			// 		}
			// 	}
			// );
		},
		subscribe() {
			// let vm = this;
			// if (!this.isSubscribe) {
			// 	this.isSubscribeLoading = true;
			// 	let sendType = "bcc";

			// 	if (this.userRole == "admin") {
			// 		sendType = "to";
			// 	}
			// 	console.log(this.userRole);
			// 	console.log(sendType);
			// 	SettingService.SubscribeAlarm(
			// 		{
			// 			type: "MandateAlarm-Receivers",
			// 			email: this.userEmail,
			// 			sendType: sendType
			// 		},
			// 		(err, response) => {
			// 			if (err) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: err,
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else if (response.toObject().msg == 0) {
			// 				vm.isSubscribe = true;
			// 				vm.promptInfoSnack({
			// 					text: "Already Subscribed",
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else {
			// 				vm.isSubscribe = !vm.isSubscribe;
			// 			}
			// 			vm.isSubscribeLoading = false;
			// 		}
			// 	);
			// } else {
			// 	this.isSubscribeLoading = true;
			// 	SettingService.UnsubscribeAlarm(
			// 		{ type: "MandateAlarm-Receivers", email: this.userEmail },
			// 		(err, response) => {
			// 			if (err) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: err,
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else if (response.toObject().msg == 0) {
			// 				vm.isSubscribe = false;
			// 				vm.promptInfoSnack({
			// 					text: "Subscriber Not Exist",
			// 					status: true,
			// 					color: "red"
			// 				});
			// 			} else {
			// 				vm.isSubscribe = !vm.isSubscribe;
			// 			}
			// 			vm.isSubscribeLoading = false;
			// 		}
			// 	);
			// }
		},
		getMandateMonitorSetting() {
			let vm = this;
			SettingService.GetMandateMonitorSetting((err, response) => {
				if (err) {
					vm.promptInfoSnack({
						text: "Mandate Empety Setting",
						status: true,
						color: "red"
					});
				} else {
					vm.mandateSetting.lotThreshold = response.toObject().lotThreshold;
					vm.mandateSetting.floatingThreshold = response.toObject().floatingThreshold;
				}
			});
		},
		toCSV() {
			this.$ToCSV("Mandate Alert", this.csvHeader, this.mandates);
		},
		close() {
			this.dialog = false;
		},
		async saveSetting() {
			let vm = this;
			this.isSettingLoading = true;
			try {
				await SettingService.SetMandateMonitorSetting({
					...this.mandateSetting,
					...this.userInfo
				});
				// await PKControlService.UpdatePKSetting(
				// 	"Mandate",
				// 	this.userInfo
				// );
				this.isSettingLoading = false;
				this.close();
				vm.promptInfoSnack({
					text: "Change Successfully",
					status: true,
					color: "success"
				});
			} catch (err) {
				this.isSettingLoading = false;
				this.close();
				vm.promptInfoSnack({ text: err, status: true, color: "red" });
			}
		},
		MandateStreaming() {
			// let vm = this;
			// this.stream = AlarmDataService.ConstructMandateStream({
			// 	...vm.userInfo
			// });
			// this.stream.on("status", function(status) {
			// 	console.log(status.code);
			// 	console.log(status.details);
			// 	console.log(status.metadata);
			// });
			// this.stream.on("end", function(end) {
			// 	// stream end signal
			// });
			// this.stream.on("data", function(response) {
			// 	if (response != null) {
			// 		let mandates = response.toObject();
			// 		vm.mandates = mandates.mandatesList.map(x => {
			// 			x.value = x.value.toFixed(2);
			// 			if (x.type == "Position") {
			// 				x.value = (x.value * 0.01).toFixed(2);
			// 			}
			// 			return x;
			// 		});
			// 	}
			// });
		},
		promptInfoSnack(message) {
			this.$store.dispatch("alarmCenter/toggleInfoSnack", message);
		}
	},
	data() {
		return {
			infos: {},
			mandates: [],
			stream: {},
			dialog: false,
			isSubscribe: false,
			isSubscribeLoading: false,
			isSettingLoading: false,
			isPersistentHint: true,
			tableSort: ["type", "value", "symbol"],
			tableSortOrder: [true, true, true],
			itemsPerPage: -1,
			search: "",
			mandateSetting: {
				lotThreshold: 0,
				floatingThreshold: 0
			},
			csvHeader: {
				symbol: "Symbol",
				book: "Book",
				type: "Type",
				value: "Value"
			},
			headers: [
				{ text: "Symbol", value: "symbol" },
				{ text: "Book", value: "book" },
				{ text: "Type", value: "type" },
				{ text: "Value", value: "value" }
			]
		};
	}
};
</script>
